<template>
    <div>
        <!-- <div class="banner"><img src="../../assets/images/banner/hzhb.png" alt=""></div> -->
        <banner></banner>
        <div class="center w-1300">
            <div class="center-title">
                <div class="center-text">合作伙伴</div>
                <div class="center-p">专注于服务出口企业，打造N+1网络营销模式，取得真实询盘才是解决之道！</div>
            </div>
            <div class="huoban">
                <div class="huoban-img"><img src="../../assets/images/huoban.png" alt=""></div>
                <div class="huoban-list">
                    <div class="huoban-item" @click="goTo('/partner_list',{num:0})">
                        <div class="huoban-item-img"><img src="../../assets/images/hezuo.png" alt=""></div>
                        <div class="huoban-title">
                            <div class="huoban-text">国内合作中心</div>
                            <div class="huoban-p">专注于服务出口企业，打造N+1网络营销模式，取得真实询盘才是解决之道！</div>
                        </div>
                    </div>
                    <div class="huoban-item" @click="goTo('/partner_list',{num:1})">
                        <div class="huoban-item-img"><img src="../../assets/images/hezuo.png" alt=""></div>
                        <div class="huoban-title">
                            <div class="huoban-text">海外合作中心</div>
                            <div class="huoban-p">专注于服务出口企业，打造N+1网络营销模式，取得真实询盘才是解决之道！</div>
                        </div>
                    </div>
                    <div class="huoban-item" v-for="(item,index) in myList.slice(0,4)" :key="index" @click="goTo('partner_liebiao',{num:2,id:item.id})">
                        <div class="huoban-item-img"><img src="../../assets/images/hezuo.png" alt=""></div>
                        <div class="huoban-title">
                            <div class="huoban-text">{{item.name}}</div>
                            <div class="huoban-p">专注于服务出口企业，打造N+1网络营销模式，取得真实询盘才是解决之道！</div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        <div class="lxwm-bottom">
            <img :src="gg[0]?gg[0].pic_url:require('../../assets/images/huoban1.png')" alt="">
        </div>

        <div class="bottom w-1300">
            <div class="huoban-item"  v-for="(item,index) in myList.slice(4,10)" :key="index"  @click="goTo('partner_liebiao',{num:2,id:item.id})">
                <div class="huoban-item-img"><img src="../../assets/images/hezuo.png" alt=""></div>
                <div class="huoban-title">
                    <div class="huoban-text">{{item.name}}</div>
                    <div class="huoban-p">专注于服务出口企业，打造N+1网络营销模式，取得真实询盘才是解决之道！</div>
                </div>
            </div>            
        </div>

        <div class="lxwm-bottom">
            <img src="../../assets/images/lxwm-bottom.png" alt="">
        </div>

    </div>
</template>

<script>
    import {getplates,getplateAds} from '../../api/index'
    import banner from '../../components/banner.vue'
    export default {
        name:'partner',
        data() {
            return {
                myList:[],
                gg:[]          
            }
        },
        created() {
            this.getplates()   
            this.getplateAds()         
        },
        components:{
            banner
        },
        methods: {
            // 广告
            getplateAds(){
                getplateAds({
                    plate_id:26,
                    seat:0
                }).then((res)=>{
                    // console.log(res)
                    if(res.code==200){
                        this.gg=res.data
                    }
                })
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ path: url ,query});
            },
            getplates(){
                getplates({
                    p_id:26
                }).then((res)=>{
                    // console.log(res);
                    if (res.code==200) {
                        this.myList=res.data
                    }
                })
            },
            
        },
        
    }
</script>

<style lang="less" scoped>
    .banner{
        width: 100%;
        img{width: 100%;}
    }
    .center{
        margin: auto;
        .center-title{
            text-align: center;overflow: hidden;
            .center-text{color: #222222;font-size: 35px;font-family: Source Han Sans CN;margin-top: 60px;}
            .center-p{color: #222222;font-size: 16px;margin-top: 15px;}
        }
        .huoban{
            margin-top: 80px;margin-bottom:70px;display: flex;justify-content: space-around;align-items: center;
            .huoban-img{width: 450px;flex-shrink: 0;img{width: 100%;}}
            .huoban-list{
                display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;
                .huoban-item{
                    width: 42%;display: flex;align-items: center;margin-left:0;margin-bottom:85px;cursor: pointer;
                    .huoban-item-img{flex-shrink: 0;
                        width: 75px;height: 75px;background: #0071DB;border-radius: 50%;display: flex;align-items: center;justify-content: center;
                        img{width: 40px;height: 40px;}
                    }
                    .huoban-title{
                        margin-left: 15px;
                        .huoban-text{color: #222222;font-size: 24px;font-family: Source Han Sans CN;}
                        .huoban-p{color: #222222;font-size: 15px;font-weight: 300;margin-top: 10px;}
                    }
                }
                .huoban-item:nth-child(2n+1){margin-left:60px;}
                .huoban-item:nth-child(5), .huoban-item:nth-child(6){margin-bottom:0;}
            }
        }
    }
    .bottom{
        margin:100px auto 0 auto;display: flex;flex-direction: row;flex-wrap: wrap;
        .huoban-item{
            width: 30%;display: flex;align-items: center;margin-bottom:80px;margin-left:60px;cursor: pointer;
            .huoban-item-img{flex-shrink: 0;
                width: 75px;height: 75px;background: #0071DB;border-radius: 50%;display: flex;align-items: center;justify-content: center;
                img{width: 40px;height: 40px;}
            }
            .huoban-title{
                margin-left: 15px;
                .huoban-text{color: #222222;font-size: 24px;font-family: Source Han Sans CN;}
                .huoban-p{color: #222222;font-size: 15px;font-weight: 300;margin-top: 10px;}
            }
        }
        .huoban-item:nth-child(1), .huoban-item:nth-child(4){margin-left:0;}
        .huoban-item:nth-child(4), .huoban-item:nth-child(5), .huoban-item:nth-child(6){margin-bottom:0;}
    }


    .lxwm-bottom{
        width: 100%;margin-top:50px;
        img{width: 100%;display: flex;}
    }
</style>